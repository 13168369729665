<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="author-area pb-0">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-12">
                            <!-- Intro -->
                            <div class="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div class="intro-content">
                                    <span>Get Started</span>
                                    <h3 class="mt-3 mb-0">Create Fair ERC20</h3>
                                </div>
                            </div>
                            <!-- Item Form -->
                            <form class="item-form card no-hover">
                                <div class="row">

                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <input type="text" v-model="form.name" class="form-control" name="name"
                                                   placeholder="Token Name" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <input type="text" v-model="form.symbol" class="form-control" name="name"
                                                   placeholder="Token Symbol" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <input type="text" v-model="form.totalPer" class="form-control" name="name"
                                                   placeholder="Number of Maxs(How many times members are allowed to maxs)" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <input type="text" v-model="form.amountPerAddress" class="form-control"
                                                   name="name"
                                                   placeholder="Mint Limit(Specify the maximum tokens that can be minted per transaction by a user.)"
                                                   required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button @click="createFn" class="btn w-100 mt-3 mt-sm-4" type="button">Create
                                            Fair ERC20
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <div class="modal fade" id="createModalCenter" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="padding-top: 0;">
                            <h5 class="modal-title" id="exampleModalCenterTitle">Transaction Pending</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="padding-top: 0;">
                            <a target="_blank" :href="$g.blockExplorerUrls+'tx/'+hash" style="word-break: break-all">
                                {{hash}}
                            </a>
                        </div>
                        <div class="modal-footer">

                            <button style="padding: 8px 34px;" type="button" class="btn btn-secondary">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <section class="work-area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Intro -->
                            <div class="intro mb-4">
                                <div class="intro-content">
                                    <span>How It Works</span>
                                    <h3 class="mt-3 mb-0">Create your Fair Launch ERC20 token</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row items">
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-wallet text-effect"></i>
                                <h4>Set up your wallet</h4>
                                <p>Once you’ve set up your wallet of choice, connect it to Wallet by clicking the Wallet
                                    Connect in the top right corner. Learn about the wallets we support.</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-grid text-effect"></i>
                                <h4>Create your Fair Launch ERC20 token</h4>
                                <p>Click Create and set up your Fair Launch ERC20</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-drawer text-effect"></i>
                                <h4>Add your ERC20 Info</h4>
                                <p>set ERC20 Info:Token Name,Token Symbol,Total Per,Amount Per Address</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-bag text-effect"></i>
                                <h4>List them for mint</h4>
                                <p>Within approximately 24 hours, will list</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "createNFT",
        components: {
            heads,
            footers
        },
        data() {
            return {
                form: {
                    name: '',
                    symbol: '',
                    totalPer: '',
                    amountPerAddress: ''
                },
                hash: ''
            }
        },
        methods: {
            async createFn() {

                if (this.form.name.trim() != ''
                    && this.form.symbol.trim() != ''
                    && this.form.totalPer.trim() != ''
                    && this.form.amountPerAddress.trim() != ''
                    && this.$store.state.addr != '') {
                    this.hash = ''
                    $('#createModalCenter').modal('show')

                    this.hash = await this.$g.deployFairlaunch(this.form, this.$store.state.addr)

                    if (this.hash != '') {
                        let tt = await this.$g.eth_getTransactionReceipt(this.hash);
                        if (tt == '0x1') {
                        } else if (tt == '0x0') {

                        }
                        let ress = await this.$g.eth_getTransactionReceipts(this.hash)
                        this.$g.addcoin(ress.contractAddress, this.form.symbol.trim())
                        $('#createModalCenter').modal('hide')
                        await this.$g.getReview(ress.contractAddress)
                        alert("We will review it within 24 hours")

                        this.$router.push({path: '/detailsERC20?address=' + ress.contractAddress})

                    } else {
                        $('#createModalCenter').modal('hide')

                    }
                }

            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
