<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="explore-area " style="margin-top: 20px">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="intro d-flex justify-content-between align-items-end m-0">
                                <div class="intro-content">
                                    <span>Exclusive Assets</span>
                                    <h3 class="mt-3 mb-0">ERC20 Explore
                                    </h3>
                                    <a @click="isShow=true" style="color: #fff;"
                                       class="d-block btn btn-bordered-white mt-4">
                                        Search
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isShow" class="modal fade p-0 show" style="display: block;">
                        <div class="modal-dialog dialog-animated">
                            <div class="modal-content h-100">
                                <div class="modal-header" data-dismiss="modal">
                                    Search
                                    <svg @click="isShow=false" class="svg-inline--fa fa-times-circle fa-w-16 icon-close"
                                         aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle"
                                         role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                         data-fa-i2svg="">
                                        <path fill="currentColor"
                                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
                                    </svg>
                                    <!-- <i class="far fa-times-circle icon-close"></i> Font Awesome fontawesome.com -->
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-12 align-self-center">
                                            <div class="row">
                                                <div class="col-12 pb-3">
                                                    <h2 class="search-title mt-0 mb-3">What are you Search for?</h2>
                                                    <p>Currently, we support various details including ERC20 contract
                                                        address, ERC20 Name, ERC20 Symbol, the creator's address, and
                                                        the owner's address.</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 input-group mt-4">
                                                    <input v-model="search" type="text"
                                                           placeholder="Enter your keywords">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 input-group align-self-center">
                                                    <button @click="searchfn" class="btn btn-bordered-white mt-3">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!--                    <div class="row justify-content-center text-center" style="padding-top: 20px">-->
                    <!--                        <div class="col-12">-->
                    <!--                            <div class="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-4" data-toggle="buttons">-->
                    <!--                                <label class="btn d-table text-uppercase p-2 active" >-->
                    <!--                                    <input type="radio" value="all" checked="" class="explore-btn-1">-->
                    <!--                                    <span>All</span>-->
                    <!--                                </label>-->
                    <!--                                <label class="btn d-table text-uppercase p-2">-->
                    <!--                                    <input type="radio" value="collectibles" class="explore-btn-1">-->
                    <!--                                    <span>New</span>-->
                    <!--                                </label>-->
                    <!--                                <label class="btn d-table text-uppercase p-2">-->
                    <!--                                    <input type="radio" value="art" class="explore-btn-1">-->
                    <!--                                    <span>Create</span>-->
                    <!--                                </label>-->
                    <!--                                <label class="btn d-table text-uppercase p-2">-->
                    <!--                                    <input type="radio" value="music" class="explore-btn-1">-->
                    <!--                                    <span>Mint</span>-->
                    <!--                                </label>-->

                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="row items">
                        <div v-for="item in address" class="col-12 col-sm-6 col-lg-3 item">
                            <a v-if="item.model"
                               :href="'/detailsERC20?address='+item.address">
                                <div class="card">
                                    <div class="image-over"
                                         style="font-size: 1.6rem;line-height: 140px;text-align: center;color: #fff;height: 150px;background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);">
                                        ${{item.model.symbol}}
                                    </div>
                                    <div class="card-caption col-12 p-0">
                                        <div class="card-body" style="padding-top: 10px">
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Creator By</span>
                                                <a :href="$g.blockExplorerUrls+'address/'+item.model.creator">
                                                    <h6 class="ml-2 mb-0" style="    word-break: break-all;">
                                                        {{item.model.hensname}}</h6>
                                                </a>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">1 Per</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat($g.powSum(item.model.amountPerAddress,18)).toLocaleString()}}
                                                    ${{item.model.symbol}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Remaining</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.remainingAddresses).toLocaleString()}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Total Per</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.totalPer).toLocaleString()}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Creation Block</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.creationBlock).toLocaleString()}}</h6>
                                            </div>
                                            <div v-if="item.holders" class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Holders</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{item.holders.toLocaleString()}}</h6>
                                            </div>
                                            <!--                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i-->
                                            <!--                                                class="icon-layers mr-2"></i>-->
                                            <!--                                            Free Mint-->
                                            <!--                                        </a>-->
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div @click="addPgae" v-if="ispage" class="row">
                        <div class="col-12 text-center">
                            <a id="load-btn" class="btn btn-bordered-white mt-5">Load More</a>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "exploreNFT",
        components: {
            heads,
            footers
        },
        data() {
            return {
                isShow: false,
                address: [],
                model: {
                    name: '',
                    symbol: '',
                    totalSupplyCap: '',
                    amountPerAddress: '',
                    remainingAddresses: '',
                    totalPer: '',
                    creationBlock: '',
                    creator: '',
                    owner: '',
                },
                page: 1,
                ispage: false,
                search: '',
            }
        },
        methods: {
            searchfn() {
                this.page = 1;
                this.address=[]
                this.isShow=false;
                this.load();
            },
            addPgae() {
                this.page = this.page + 1;
                this.load();
            },
            async load() {
                let address = await this.$g.getReviewFairlaunchList(this.page, this.search)
                if (address.length == 8) {
                    this.ispage = true;
                } else {
                    this.ispage = false;
                }
                for (let i = 0; i < address.length; i++) {
                    let model = address[i];
                    model.model = await this.$g.getContractDetails(model.address);
                    model.model.hensname = model.creator;
                    this.address.push(model)
                }
                for (let i = 0; i < this.address.length; i++) {
                    let model = this.address[i];
                    let name = await this.$g.getName(model.creator);
                    if (name != '') {
                        model.model.hensname = name;
                    }
                    this.address = JSON.parse(JSON.stringify(this.address))
                }
            }
        },
        async mounted() {
            this.load()
        }
    }
</script>

<style scoped>

</style>
