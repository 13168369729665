<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="item-details-area">

                <div class="container" style="margin-top: 20px">
                    <div class="row justify-content-between">
                        <div data-v-1edaef43="" class="col-12">
                            <div data-v-1edaef43="" class="intro d-flex justify-content-between align-items-end m-0">
                                <div data-v-1edaef43="" class="intro-content"><span
                                        data-v-1edaef43="">Exclusive Assets</span>
                                    <h3 data-v-1edaef43="" class="mt-3 mb-0">ERC20 Details</h3></div>
                            </div>
                        </div>
                        <div style="padding-top: 20px" class="col-12 col-lg-5">
                            <div class="item-info">

                                <div class="item-thumb text-center"
                                     style="font-size: 1.6rem;line-height: 340px;text-align: center;color: #fff;height: 350px;background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);">
                                    ${{model.symbol}}
                                </div>
                                <a style="color: #fff" v-if="model.remainingAddresses!=0&&hasClaimed==false"
                                   @click="freeMint"
                                   class="d-block btn btn-bordered-white mt-4">
                                    Free Mint
                                </a>
                                <a style="color: #fff"
                                   :href="'https://app.hebeswap.com/#/swap?inputCurrency='+address"
                                   class="d-block btn btn-bordered-white mt-4">
                                    Swap ${{model.symbol}}
                                </a>
                                <!--                                <div class="tab-content" id="nav-tabContent">-->
                                <!--                                    <div class="tab-pane fade show active" id="nav-home">-->
                                <!--                                        <ul v-for="item in 2" class="list-unstyled">-->
                                <!--                                            <li class="single-tab-list d-flex align-items-center">-->
                                <!--                                                <p class="m-0">Mint 2023-11-08 21:19:33 <br>by-->
                                <!--                                                    <a style="word-break: break-all"-->
                                <!--                                                       :href="$g.blockExplorerUrls+'address/'+model.creator">0xaF5da9De7B4691C60F9aAaB68Fa49FCd01114Ee0</a>-->
                                <!--                                                </p>-->
                                <!--                                            </li>-->
                                <!--                                        </ul>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <!-- Content -->
                            <div class="content mt-5 mt-lg-0">
                                <h3 class="m-0">{{model.name}}</h3>
                                <div class="item-info-list mt-4">
                                    <ul class="list-unstyled">
                                        <li class="price d-flex justify-content-between">
                                            <span>Mint Limit</span>
                                            <span>{{parseFloat($g.powSum(model.amountPerAddress,18)).toLocaleString()}} ${{model.symbol}}</span>
                                        </li>
                                        <li class="price d-flex justify-content-between">
                                            <span>Number of Mints</span>
                                            <span>{{parseFloat(model.remainingAddresses).toLocaleString()}}</span>
                                        </li>
                                        <li class="price d-flex justify-content-between">
                                            <span>Number of Maxs</span>
                                            <span>{{parseFloat(model.totalPer).toLocaleString()}}</span>
                                        </li>
                                        <li class="price d-flex justify-content-between">
                                            <span>Max Supply</span>
                                            <span>{{parseFloat($g.powSum(model.totalSupplyCap,18)).toLocaleString()}} ${{model.symbol}}</span>
                                        </li>
                                        <li class="price d-flex justify-content-between">
                                            <span>Creation Block</span>
                                            <span>{{parseFloat(model.creationBlock).toLocaleString()}}</span>
                                        </li>
                                        <li v-if="model.holders" class="price d-flex justify-content-between">
                                            <span>Holders</span>
                                            <span>{{parseFloat(model.holders).toLocaleString()}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div v-if="model.creator!=model.owner" class="row items">
                                    <div class="col-12 col-md-6 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">

                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" style="word-break: break-all"
                                                       :href="$g.blockExplorerUrls+'address/'+model.creator">{{model.hensname}}</a>
                                                    <span>Creator</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">
                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" style="word-break: break-all"
                                                       :href="$g.blockExplorerUrls+'address/'+model.owner">{{model.owner}}</a>
                                                    <span>Owner</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="model.creator==model.owner" class="row items">
                                    <div class="col-12 col-md-12 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">

                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" style="word-break: break-all"
                                                       :href="$g.blockExplorerUrls+'address/'+model.creator">
                                                        {{model.hensname}}
                                                    </a>
                                                    <span>Creator</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row items">
                                    <div class="col-12 col-md-12 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">

                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" style="word-break: break-all"
                                                       :href="$g.blockExplorerUrls+'address/'+address">{{address}}</a>
                                                    <span>Contract</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Please note: This ERC20 token was created through a Fair Launch contract. We do not
                                    assume any guarantees or responsibilities for associated risks. Exercise caution and
                                    assume all risks associated with its use.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="padding-top: 0;">
                            <h5 class="modal-title" id="exampleModalCenterTitle">Transaction Pending</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="padding-top: 0;">
                            <a :href="$g.blockExplorerUrls+'tx/'+hash" style="word-break: break-all">
                                {{hash}}
                            </a>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "detailsERC20",
        components: {
            heads,
            footers
        },
        data() {
            return {
                address: '',
                model: {
                    name: '',
                    symbol: '',
                    totalSupplyCap: '',
                    amountPerAddress: '',
                    remainingAddresses: '',
                    totalPer: '',
                    creationBlock: '',
                    creator: '',
                    owner: '',
                    holders: ''
                },
                myTimer: '',
                hasClaimed: false,
                hash: ''
            }
        },
        methods: {
            async freeMint() {
                if (this.$store.state.addr != '') {

                    let hash = await this.$g.getfreeMint(this.$store.state.addr, this.address);
                    this.hash = hash;

                    if (hash != '') {
                        $('#exampleModalCenter').modal('show')
                        let tt = await this.$g.eth_getTransactionReceipt(hash);
                        if (tt == '0x1') {
                        } else if (tt == '0x0') {

                        }
                        $('#exampleModalCenter').modal('hide')

                        this.load()
                    } else {
                    }
                }
            },
            async load() {
                if (this.$route.query.address) {
                    if (this.$g.isEvmAddress(this.$route.query.address)) {
                        this.address = this.$route.query.address
                        this.model = await this.$g.getContractDetails(this.address);
                        this.model.hensname = this.model.creator;
                        let name = await this.$g.getName(this.model.creator);
                        if (name != '') {
                            this.model.hensname = name;
                        }
                        let address = await this.$g.getReviewFairlaunchList(1, this.address)
                        if (address[0] && address[0].holders) {
                            this.model.holders = address[0].holders
                            this.model = JSON.parse(JSON.stringify(this.model))
                        }

                    } else {
                        this.$router.push({path: '/'})

                    }
                }
            }
        },
        beforeDestroy() {
            if (this.myTimer) {
                clearInterval(this.myTimer);
            }
        },
        async mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
