<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="author-area pb-0">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-12">
                            <!-- Intro -->
                            <div class="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div class="intro-content">
                                    <span>Get Started</span>
                                    <h3 class="mt-3 mb-0">Create NFT</h3>
                                </div>
                            </div>
                            <!-- Item Form -->
                            <form class="item-form card no-hover">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-group form-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="inputGroupFile01">
                                                <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <input type="text" class="form-control" name="name" placeholder="Item Name" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea class="form-control" name="textarea" placeholder="Description" cols="30" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="price" placeholder="Item Price" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="royality" placeholder="Royality" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Size" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="copies" placeholder="No of Copies" required="required">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mt-3">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked="">
                                                <label class="form-check-label" for="inlineRadio1">Put on Sale</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                                <label class="form-check-label" for="inlineRadio2">Instant Sale Price</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                                <label class="form-check-label" for="inlineRadio3">Unlock Purchased</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn w-100 mt-3 mt-sm-4" type="submit">Create Item</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section class="work-area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Intro -->
                            <div class="intro mb-4">
                                <div class="intro-content">
                                    <span>How It Works</span>
                                    <h3 class="mt-3 mb-0">Create and sell your NFT</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row items">
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-wallet text-effect"></i>
                                <h4>Set up your wallet</h4>
                                <p>Once you’ve set up your wallet of choice, connect it to Wallet  by clicking the Wallet Connect in the top right corner. Learn about the wallets we support.</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-grid text-effect"></i>
                                <h4>Create your collection</h4>
                                <p>Click Create and set up your collection.set a secondary sales fee.</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-drawer text-effect"></i>
                                <h4>Add your NFT</h4>
                                <p>Upload your work image / text .</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <!-- Single Work -->
                            <div class="single-work">
                                <i class="icons icon-bag text-effect"></i>
                                <h4>List them for sale</h4>
                                <p>Choose ETC / ETC ERC20 listings. You choose how you want to sell your NFTs!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "createNFT",
        components: {
            heads,
            footers
        },
        created() {
            $('#netstorm-preloader').addClass('loaded');
        }
    }
</script>

<style scoped>

</style>
