const Web3 = require('web3');
let web3 = new Web3();
import axios from 'axios'

axios.defaults.timeout = 5000;
//
// let network = {
//     chainId: '0x3f',
//     rpcUrls: ['https://rpc.mordor.etccooperative.org'],
//     chainName: 'Ethereum Classic Mordor',
//     nativeCurrency: {name: 'ETC', decimals: 18, symbol: 'ETC'},
//     blockExplorerUrls: ['https://etc-mordor.blockscout.com/']
// };

let network = {
    chainId: '0x3d',
    rpcUrls: ['https://etc.rivet.link'],
    chainName: 'Ethereum Classic',
    nativeCurrency: {name: 'ETC', decimals: 18, symbol: 'ETC'},
    blockExplorerUrls: ['https://etc.blockscout.com/'],
    iconUrls: ['https://hebe.cc/assets/logo/etc_logo.png', 'https://hebe.cc/assets/logo/etc_logo.png']
}
let rpcUrls = [
    'https://etc.mytokenpocket.vip',
    'https://etc.rivet.link',
    'https://etc.etcdesktop.com'
];

let gasLimit = 150000
let gas = 2

export default {
    addr: '',
    blockExplorerUrls: network.blockExplorerUrls[0],
    chainName: network.chainName,
    rpc: '',
    api: "https://api.etcinscribe.com/",
    async etclogin() {
        return new Promise(async (resolve, reject) => {
            let addr = await this.getAddr()
            let chainId = await ethereum.request({method: 'eth_chainId'})
            if (chainId != network.chainId) {
                await this.addEthereumChain()
            }
            resolve(addr)
        })
    },
    async addEthereumChain() {

        await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                network
            ]
        })
    },
    async getAddr() {
        return new Promise(async (resolve, reject) => {
            try {
                ethereum
                    .request({method: 'eth_requestAccounts'})
                    .then((res) => {
                        resolve(res[0].toLowerCase())
                    })
                    .catch((err) => {
                        if (ethereum.selectedAddress == null) {
                            setTimeout(() => {
                                resolve(this.getAddr())
                            }, 2000)
                        } else {
                            resolve(ethereum.selectedAddress.toLowerCase())
                        }
                    })

            } catch (e) {
                ethereum
                    .request({method: 'eth_requestAccounts'})
                    .then((res) => {
                        resolve(res[0].toLowerCase())
                    })
                    .catch((err) => {
                        setTimeout(() => {
                            resolve('')
                        }, 2000)
                    })
            }

        })
    },
    async holders(addr) {
        return new Promise(async (resolve, reject) => {
            axios.get("https://etc.blockscout.com/api/v2/addresses/" + addr,
                {timeout: 3000}
            ).then(res => {
                resolve(res.data.token.holders)
            }).catch(err => {
                resolve('https://etc.rivet.link')
            })
        })
    },
    async rpcload() {
        return new Promise(async (resolve, reject) => {
            rpcUrls.forEach(url => {
                axios.post(url, {
                    jsonrpc: '2.0',
                    method: 'eth_blockNumber',
                    params: [],
                    id: 1
                })
                    .then(response => {
                        resolve(url)
                    })
                    .catch(error => {
                        resolve(url)

                    });
            })
        })
    },
    async getContractDetails(addr) {
        return new Promise(async (resolve, reject) => {
            if (this.rpc == '') {
                let rpc = await this.rpcload();
                this.rpc = rpc;
            }
            network.rpcUrls[0] = this.rpc;
            let getAbi = web3.eth.abi.encodeFunctionCall({
                name: 'getContractDetails',
                type: 'function',
                'inputs': []
            }, [])
            let res = await this.GetEtcRpc(addr, getAbi);
            let data = web3.eth.abi.decodeLog(['string', 'string', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256', 'address', 'address'], res)
            resolve({
                name: data[0],
                symbol: data[1],
                totalSupplyCap: data[2],
                amountPerAddress: data[3],
                remainingAddresses: data[4],
                totalPer: data[5],
                creationBlock: data[6],
                creator: data[7],
                owner: data[8],
            });
        })
    },
    async GetEtcRpc(to, data) {
        return new Promise(async (resolve, reject) => {

            if (typeof (ethereum) !== 'undefined') {
                ethereum.request({
                    'method': 'eth_call',
                    'params': [{
                        'to': to,
                        'data': data
                    }, 'latest']
                }).then(async (res) => {
                    resolve(res)
                }).catch((err) => {
                    resolve('')
                })
            } else {
                axios.post(network.rpcUrls[0], {
                    'jsonrpc': '2.0',
                    'method': 'eth_call',
                    'params': [{
                        'to': to,
                        'data': data
                    }, 'latest'],
                    'id': 66
                }).then(async res => {
                    resolve(res.data.result)
                }).catch((err) => {
                    resolve('')
                })
            }
        })

    },
    async eth_getTransactionReceipt(tx) {
        return new Promise(async (resolve, reject) => {
                ethereum.request({
                    'method': 'eth_getTransactionReceipt',
                    'params': [tx]
                }).then(async (res) => {
                    if (res == null) {
                        setTimeout(() => {
                            resolve(this.eth_getTransactionReceipt(tx))
                        }, 3000)
                        return
                    } else if (res.status) {
                        resolve(res.status)
                    }
                })
                    .catch((err) => {
                        resolve('')
                    })
            }
        )
    },
    async getName(addr) {
        return new Promise(async (resolve, reject) => {
            try {
                addr = addr.split('0x')[1].toLocaleLowerCase();
                let res = await this.GetEtcRpc("0x03f4a95d964d364614E514e8638d61CDEed4f8D4", "0xba683759000000000000000000000000" + addr);
                let data = web3.eth.abi.decodeLog(['string'], res)
                if (data[0] === null) {
                    resolve('')
                } else {
                    resolve(data[0])

                }
            } catch (e) {
                resolve('')
            }
        });
    },
    async gethasClaimed(addr, address) {
        return new Promise(async (resolve, reject) => {
            let getAbi = web3.eth.abi.encodeFunctionCall({
                name: 'hasClaimed',
                type: 'function',
                'inputs': [
                    {
                        'internalType': 'address',
                        'name': '',
                        'type': 'address'
                    }
                ]
            }, [addr])
            let res = await this.GetEtcRpc(address, getAbi);
            let data = web3.eth.abi.decodeLog(['bool'], res)
            resolve(data[0]);
        })
    },
    async getfreeMint(addr, address) {
        return new Promise(async (resolve, reject) => {
            let getAbi = web3.eth.abi.encodeFunctionCall({
                name: 'claimTokens',
                type: 'function',
                'inputs': []
            }, [])
            const transactionParameters = {
                gasPrice: web3.utils.toHex((gas * 10e+8)),
                gas: web3.utils.toHex(gasLimit),
                to: address,
                from: addr,
                value: web3.utils.toHex(parseInt(0)),
                data: getAbi,
                chainId: network.chainId
            };
            try {
                const txHash = await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters]
                })
                resolve(txHash)

            } catch (e) {
                resolve('')
            }
        })
    },
    async deployFairlaunch(form, addr) {
        return new Promise(async (resolve, reject) => {
            try {
                let model = require('./Fairlaunch')
                let gasLimit = await ethereum.request({
                    method: 'eth_estimateGas',
                    params: [
                        {
                            'to': '0x9B5fbEdAA0c2474b43e4f96F58E62f702614C8B8',
                            'data': model.default.bytecode,
                            'arguments': addr
                        }]
                })
                let transactionParameters = {
                    gasPrice: web3.utils.toHex(parseInt(1 * 10e+8)),
                    gasLimit: gasLimit,
                    to: '0x',
                    from: addr,
                    value: web3.utils.toHex(0),
                    data: new web3.eth.Contract(model.default.abi)
                        .deploy({
                            data: model.default.bytecode,
                            arguments: [form.name, form.symbol, form.totalPer, form.amountPerAddress] // Writing you arguments in the array
                        }).encodeABI(),
                    chainId: network.chainId
                }
                let txHash = await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters]
                })
                resolve(txHash)
            } catch (e) {
                resolve('')
            }

        });
    },
    async addcoin(address, symbol) {
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: address, // The address that the token is at.
                    symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18 // The number of decimals in the token
                }
            }
        })

    },
    async eth_getTransactionReceipts(tx) {
        return new Promise(async (resolve, reject) => {
                ethereum.request({
                    'method': 'eth_getTransactionReceipt',
                    'params': [tx]
                }).then(async (res) => {
                    resolve(res)
                })
                    .catch((err) => {
                        resolve('')
                    })
            }
        )
    },
    async getReviewFairlaunchList(num, key) {
        return new Promise(async (resolve, reject) => {
            axios.get(this.api + "reviewFairlaunchList?num=" + num + "&&key=" + key).then(res => {
                resolve(res.data)
            })
        })
    },
    async getReview(addr) {
        return new Promise(async (resolve, reject) => {
            axios.get(this.api + "review?address=" + addr).then(res => {
                resolve(res.data)
            })
        })
    },
    getLastSegment(str) {
        let regex = /[^.]+$/;
        let matches = str.match(regex);
        if (matches && matches.length > 0) {
            return matches[0];
        }
        return '';
    },
    getLastSegment1(str) {
        let regex = /(.+)\.[^.]+$/; // 匹配最后一个点号之前的字符
        let matches = str.match(regex);
        if (matches && matches.length > 1) {
            return matches[1];
        }
        return ''; // 如果没有匹配到，则返回 null 或其他适当的值
    },
    days(height) {
        let dayblock = 6660
        return parseInt(height / dayblock).toLocaleString() + ' Day'
    },
    byteCount(str) {
        let encoder = new TextEncoder();
        let encodedBytes = encoder.encode(str);
        return encodedBytes.length;
    },
    formatDateTime(timeStamp) {
        let date = new Date(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        let second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },
    imgError() {
        let img = event.srcElement;
        img.src = empty;
        img.onerror = null; //防止闪图
    },
    numFormat(num) {
        let res = num.toString().replace(/\d+/, function (n) { // 先提取整数部分
            return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
                return $1 + ",";
            });
        })
        return res;
    },
    priceSum(price) {
        price = parseFloat(price)
        if (price == 0) {
            return 0;
        }
        if (parseFloat(price) > 1) {
            return this.powSum(price, 6)
        } else {
            if ((Math.round(price * 100) / 100) != 0) {
                return this.powSum(price, 6)
            }
            if ((Math.round(price * 1000) / 1000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 10000) / 10000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 100000) / 100000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 1000000) / 1000000) != 0) {
                return this.powSum(price, 7)
            }

            if ((Math.round(price * 10000000) / 10000000) != 0) {
                return this.powSum(price, 8)
            }

            if ((Math.round(price * 100000000) / 100000000) != 0) {
                return this.toNumberStr(price, 10)
            }

            if ((Math.round(price * 10000000000) / 10000000000) != 0) {
                return this.toNumberStr(price, 12)
            }

            if ((Math.round(price * 1000000000000) / 1000000000000) != 0) {
                return this.toNumberStr(price, 14)

            }

            if ((Math.round(price * 100000000000000) / 100000000000000) != 0) {
                return this.toNumberStr(price, 16)
            }
            if (price == 0) {
                return 0;
            }
        }
    },
    powSum(sum, n) {
        return Math.round(sum * Math.pow(10, n) / Math.pow(10, n)) / Math.pow(10, n);
    },
    toNumberStr(num, digits) {
        if (/^(\d+(?:\.\d+)?)(e)([\-]?\d+)$/.test(num)) {
            let temp = /^(\d{1,}(?:,\d{3})*\.(?:0*[1-9]+)?)(0*)?$/.exec(num.toFixed(digits));
            if (temp) {
                return temp[1];
            } else {
                return num.toFixed(digits)
            }
        } else {
            return "" + num
        }
    },
    price(price) {
        price = parseFloat(price)
        if (price == 0) {
            return 0;
        }
        if (parseFloat(price) > 1) {
            return this.powSum(price, 4).toLocaleString()
        } else {

            if ((Math.round(price * 100) / 100) != 0) {
                return this.powSum(price, 6)
            }
            if ((Math.round(price * 1000) / 1000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 10000) / 10000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 100000) / 100000) != 0) {
                return this.powSum(price, 6)
            }

            if ((Math.round(price * 1000000) / 1000000) != 0) {
                return this.powSum(price, 7)
            }

            if ((Math.round(price * 10000000) / 10000000) != 0) {
                return this.powSum(price, 8)
            }

            if ((Math.round(price * 100000000) / 100000000) != 0) {
                return this.toNumberStr(price, 10)
            }

            if ((Math.round(price * 10000000000) / 10000000000) != 0) {
                return this.toNumberStr(price, 12)
            }

            if ((Math.round(price * 1000000000000) / 1000000000000) != 0) {
                return this.toNumberStr(price, 14)

            }

            if ((Math.round(price * 100000000000000) / 100000000000000) != 0) {
                return this.toNumberStr(price, 16)
            }
            if (price == 0) {
                return 0;
            }
        }
    },
    isEvmAddress(addr) {
        return web3.utils.isAddress(addr);
    },
    mode(type, _this) {
        if (type == "day") {
            document.getElementById("html").classList.remove("theme-dark");
            localStorage.setItem('mode', 'day')
            _this.modeType = "day"
        } else {
            document.getElementById("html").classList.add("theme-dark");
            localStorage.setItem('mode', 'dark')
            _this.modeType = "dark"
        }
    },
    formatDateTimeT(timeStamp) {
        let date = new Date(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        let second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },

}
