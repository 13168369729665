import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import createNFT from '../views/createNFT.vue'
import createERC20 from '../views/createERC20.vue'
import exploreNFT from '../views/exploreNFT.vue'
import detailsNFT from '../views/detailsNFT.vue'
import detailsERC20 from '../views/detailsERC20.vue'

import activity from '../views/activity.vue'

import exploreERC20 from '../views/exploreERC20.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/createNFT',
    name: 'createNFT',
    component: createNFT
  },
  {
    path: '/createERC20',
    name: 'createERC20',
    component: createERC20
  },
  {
    path: '/exploreNFT',
    name: 'exploreNFT',
    component: exploreNFT
  },
  {
    path: '/exploreERC20',
    name: 'exploreERC20',
    component: exploreERC20
  },
  {
    path: '/detailsNFT',
    name: 'detailsNFT',
    component: detailsNFT
  },
  {
    path: '/detailsERC20',
    name: 'detailsERC20',
    component: detailsERC20
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
