<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="item-details-area">

                <div class="container">
                    <div class="row justify-content-between">
                        <div data-v-1edaef43="" class="col-12">
                            <div data-v-1edaef43="" class="intro d-flex justify-content-between align-items-end m-0">
                                <div data-v-1edaef43="" class="intro-content"><span
                                        data-v-1edaef43="">Exclusive Assets</span>
                                    <h3 data-v-1edaef43="" class="mt-3 mb-0">NFT Details</h3></div>
                            </div>
                        </div>
                        <div style="padding-top: 20px" class="col-12 col-lg-5">
                            <div class="item-info">
                                <div class="item-thumb text-center">
                                    <img src="assets/img/content/auction_2.jpg" alt="">
                                </div>
                                <div class="card no-hover countdown-times my-4">
                                    <div class="countdown d-flex justify-content-center" data-date="2022-03-30"
                                         style="display: none;"></div>
                                </div>
                                <!-- Netstorm Tab -->
                                <ul class="netstorm-tab nav nav-tabs" id="nav-tab">
                                    <li>
                                        <a class="active" id="nav-home-tab" data-toggle="pill" href="#nav-home">
                                            <h5 class="m-0">Bids</h5>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="nav-profile-tab" data-toggle="pill" href="#nav-profile">
                                            <h5 class="m-0">History</h5>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="nav-contact-tab" data-toggle="pill" href="#nav-contact">
                                            <h5 class="m-0">Details</h5>
                                        </a>
                                    </li>
                                </ul>
                                <!-- Tab Content -->
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-home">
                                        <ul class="list-unstyled">
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_1.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>14 ETH</strong> 4 hours ago <br>by
                                                    <a href="author.html">@arham</a></p>
                                            </li>
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_2.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>10 ETH</strong> 8 hours ago <br>by
                                                    <a href="author.html">@junaid</a></p>
                                            </li>
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_3.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>12 ETH</strong> 3 hours ago <br>by
                                                    <a href="author.html">@yasmin</a></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="nav-profile">
                                        <ul class="list-unstyled">
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_6.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>32 ETH</strong> 10 hours ago <br>by
                                                    <a href="author.html">@hasan</a></p>
                                            </li>
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_7.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>24 ETH</strong> 6 hours ago <br>by
                                                    <a href="author.html">@artnox</a></p>
                                            </li>
                                            <!-- Single Tab List -->
                                            <li class="single-tab-list d-flex align-items-center">
                                                <img class="avatar-sm rounded-circle mr-3"
                                                     src="assets/img/content/avatar_8.jpg" alt="">
                                                <p class="m-0">Bid listed for <strong>29 ETH</strong> 12 hours ago <br>by
                                                    <a href="author.html">@meez</a></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="nav-contact">
                                        <!-- Single Tab List -->
                                        <div class="owner-meta d-flex align-items-center mt-3">
                                            <span>Owner</span>
                                            <a class="owner d-flex align-items-center ml-2" href="author.html">
                                                <img class="avatar-sm rounded-circle"
                                                     src="assets/img/content/avatar_1.jpg" alt="">
                                                <h6 class="ml-2">Themeland</h6>
                                            </a>
                                        </div>
                                        <p class="mt-2">Created : 15 Jul 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <!-- Content -->
                            <div class="content mt-5 mt-lg-0">
                                <h3 class="m-0">Walking On Air</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati
                                    dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                                <!-- Owner -->
                                <div class="owner d-flex align-items-center">
                                    <span>Owned By</span>
                                    <a class="owner-meta d-flex align-items-center ml-3" href="author.html">
                                        <img class="avatar-sm rounded-circle" src="assets/img/content/avatar_1.jpg"
                                             alt="">
                                        <h6 class="ml-2">Themeland</h6>
                                    </a>
                                </div>
                                <!-- Item Info List -->
                                <div class="item-info-list mt-4">
                                    <ul class="list-unstyled">
                                        <li class="price d-flex justify-content-between">
                                            <span>Current Price 1.5 ETH</span>
                                            <span>$500.89</span>
                                            <span>1 of 5</span>
                                        </li>
                                        <li>
                                            <span>Size</span>
                                            <span>14000 x 14000 px</span>
                                        </li>
                                        <li>
                                            <span>Volume Traded</span>
                                            <span>64.1</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row items">
                                    <div class="col-12 col-md-6 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">
                                                <a href="author.html">
                                                    <img class="avatar-md rounded-circle"
                                                         src="assets/img/content/avatar_1.jpg" alt="">
                                                </a>
                                                <!-- Seller Info -->
                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" href="author.html">@ArtNoxStudio</a>
                                                    <span>Creator</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 item px-lg-2">
                                        <div class="card no-hover">
                                            <div class="single-seller d-flex align-items-center">
                                                <a href="author.html">
                                                    <img class="avatar-md rounded-circle"
                                                         src="assets/img/content/avatar_1.jpg" alt="">
                                                </a>
                                                <!-- Seller Info -->
                                                <div class="seller-info ml-3">
                                                    <a class="seller mb-2" href="explore-2.html">Virtual Worlds</a>
                                                    <span>Collection</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 item px-lg-2">
                                        <div class="card no-hover">
                                            <h4 class="mt-0 mb-2">Highest Bid</h4>
                                            <div class="price d-flex justify-content-between align-items-center">
                                                <span>2.9 BNB</span>
                                                <span>1 of 5</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a class="d-block btn btn-bordered-white mt-4" href="wallet-connect.html">Place a
                                    Bid</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "detailsNFT",
        components: {
            heads,
            footers
        },
        created() {
            $('#netstorm-preloader').addClass('loaded');
        }
    }
</script>

<style scoped>

</style>
