<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="hero-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-7">
                            <span>ETCInscribe</span>
                            <!--                            <h1 class="mt-4">Inscribe ETC NFT marketplace</h1>-->
                            <h1 class="mt-4"> Fair Launch ERC20 token</h1>

                            <p></p>

                            <!--                            <div class="button-group">-->
                            <!--                                <a class="btn btn-bordered-white" href="createNFT"><i class="icon-note mr-2"></i>Create NFT</a>-->
                            <!--                                <a class="btn btn-bordered-white" href="/"><i class="icon-rocket mr-2"></i>Explore NFT</a>-->
                            <!--                            </div>-->
                            <div class="button-group">
                                <a class="btn btn-bordered-white" href="createERC20"><i class="icon-note mr-2"></i>Create
                                    ERC20</a>
                                <a class="btn btn-bordered-white" href="exploreERC20"><i class="icon-rocket mr-2"></i>Explore
                                    ERC20</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 465" version="1.1">
                        <defs>
                            <linearGradient x1="49.7965246%" y1="28.2355058%" x2="49.7778147%" y2="98.4657689%"
                                            id="linearGradient-1">
                                <stop stop-color="rgba(69,40,220, 0.15)" offset="0%"/>
                                <stop stop-color="rgba(87,4,138, 0.15)" offset="100%"/>
                            </linearGradient>
                        </defs>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="" fill="url(#linearGradient-1)">
                                <animate id="graph-animation" xmlns="http://www.w3.org/2000/svg" dur="2s" repeatCount=""
                                         attributeName="points"
                                         values="0,464 0,464 111.6,464 282.5,464 457.4,464 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,323.3 282.5,373 457.4,423.8 613.4,464 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,336.6 457.4,363.5 613.4,414.4 762.3,464 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,323.3 613.4,340 762.3,425.6 912.3,464 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,290.4 762.3,368 912.3,446.4 1068.2,464 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,329.6 912.3,420 1068.2,427.6 1191.2,464 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,402.4 1068.2,373 1191.2,412 1328.1,464 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,336.6 1191.2,334 1328.1,404 1440.1,464 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,282 1328.1,314 1440.1,372.8 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,254 1440.1,236 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,144.79999999999998 1440.1,464 0,464; 0,464 0,367 111.6,263 282.5,282 457.4,263 613.4,216 762.3,272 912.3,376 1068.2,282 1191.2,204 1328.1,164 1440.1,8 1440.1,464 0,464;"
                                         fill="freeze"/>
                            </polygon>
                        </g>
                    </svg>
                </div>
            </section>

            <section style="padding-top:40px;padding-bottom: 40px" class="explore-area ">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- Intro -->
                            <div class="intro d-flex justify-content-between align-items-end m-0">
                                <div class="intro-content">
                                    <span>Exclusive Assets</span>
                                    <h3 class="mt-3 mb-0">ERC20 Explore</h3>
                                </div>
                                <div class="intro-btn">
                                    <a class="btn content-btn" href="/exploreERC20">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row items">
                        <div v-for="item in address" class="col-12 col-sm-6 col-lg-3 item">
                            <a v-if="item.model"
                               :href="'/detailsERC20?address='+item.address">
                                <div class="card">
                                    <div class="image-over"
                                         style="font-size: 1.6rem;line-height: 140px;text-align: center;color: #fff;height: 150px;background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);">
                                        ${{item.model.symbol}}
                                    </div>
                                    <div class="card-caption col-12 p-0">
                                        <div class="card-body" style="padding-top: 10px">
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Creator By</span>
                                                <a :href="$g.blockExplorerUrls+'address/'+item.model.creator">
                                                    <h6 v-if="item.model.hensname" class="ml-2 mb-0"
                                                        style="    word-break: break-all;">
                                                        {{item.model.hensname}}
                                                    </h6>
                                                </a>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Mint Limit</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat($g.powSum(item.model.amountPerAddress,18)).toLocaleString()}}
                                                    ${{item.model.symbol}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Number of Mints</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.remainingAddresses).toLocaleString()}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Number of Maxs</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.totalPer).toLocaleString()}}</h6>
                                            </div>
                                            <div class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Creation Block</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{parseFloat(item.model.creationBlock).toLocaleString()}}</h6>
                                            </div>
                                            <div v-if="item.holders" class="seller d-flex align-items-center my-3">
                                                <span style="color: #8E8E8E">Holders</span>
                                                <h6 class="ml-2 mb-0" style="word-break: break-all;text-align: right">
                                                    {{item.holders.toLocaleString()}}</h6>
                                            </div>
                                            <!--                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i-->
                                            <!--                                                class="icon-layers mr-2"></i>-->
                                            <!--                                            Free Mint-->
                                            <!--                                        </a>-->
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>


            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>


        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "home",
        components: {
            heads,
            footers
        },
        data() {
            return {
                address: [],
                model: {
                    name: '',
                    symbol: '',
                    totalSupplyCap: '',
                    amountPerAddress: '',
                    remainingAddresses: '',
                    totalPer: '',
                    creationBlock: '',
                    creator: '',
                    owner: '',
                }
            }
        },
        async mounted() {
            let address = await this.$g.getReviewFairlaunchList(1, '')
            for (let i = 0; i < address.length; i++) {
                let model = address[i];
                model.model = await this.$g.getContractDetails(model.address);
                model.model.hensname = model.creator;
                this.address.push(model)
            }
            for (let i = 0; i < this.address.length; i++) {
                let model = this.address[i];
                let name = await this.$g.getName(model.creator);
                if (name != '') {
                    model.model.hensname = name;
                }
                this.address = JSON.parse(JSON.stringify(this.address))
            }
        }
    }
</script>

<style scoped>

</style>
