<template>
    <footer id="contact" class="footer-area">
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Logo -->
                            <a class="navbar-brand" href="。/">
                                <img style="width: 55px" class="navbar-brand-sticky"
                                     src="assets/img/logo/logo.png" alt="">
                            </a>
                            <p>Courtesy of the HebeBlock Team</p>
                            <div class="social-icons d-flex">
                                <a class="twitter" href="https://twitter.com/EtcHebeBlock">
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <div class="footer-items">
                            <h4 class="footer-title">Useful Links</h4>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/createERC20">Create ERC20</a></li>
                                <li><a href="/exploreERC20">Explore ERC20</a></li>
                                <li><a style="cursor: pointer" @click="submitErc20">Submit ERC20</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <div class="footer-items">
                            <h4 class="footer-title">Contract</h4>
                            <ul>
                                <li><a href="https://t.me/hebeOffice">Telegram</a></li>
                                <li><a href="https://discord.com/invite/HFBUJ3AKFT">Discord</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="footer-items">
                            <h4 class="footer-title">Subscribe Us</h4>
                            <div class="subscribe-form d-flex align-items-center">
                                <input type="email" value="hebeblock@gmail.com" class="form-control"
                                       placeholder="hebeblock@gmail.com">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="submitErc20" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="padding-top: 0;">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Submit Erc20</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body author-area" style="padding-top: 0;">
                        <form style="padding: 0;" class="item-form card no-hover">
                            <div class="row">

                                <div class="col-12">
                                    <div class="form-group mt-3">
                                        <input v-model="address" type="text" class="form-control" name="name"
                                               placeholder="Address" required="required">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button @click="submitErc20Fn" type="button" class="btn btn-secondary">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left">&copy;2023 ETCInscribe, All Rights Reserved.</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right">Made with <i class="fas fa-heart"></i> By <a
                                    href="#">ETCInscribe</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "footers",
        data() {
            return {
                address: ""
            }
        },
        methods: {
            submitErc20() {
                $('#submitErc20').modal('show')
            },
            async submitErc20Fn() {
                if (this.$g.isEvmAddress(this.address)) {
                    let data = await this.$g.getReview(this.address)
                    alert("We will review it within 24 hours")
                } else {
                    alert("Error address")
                }
                $('#submitErc20').modal('hide')

            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
