<template>
    <div>
        <div id="netstorm-preloader" class="netstorm-preloader">
            <div class="preloader-animation">
                <div class="spinner"></div>
                <p class="fw-5 text-center text-uppercase">Loading</p>
            </div>
            <div class="loader-animation">
                <div class="row h-100">
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                    <div class="col-3 single-loader p-0">
                        <div class="loader-bg"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <heads></heads>

            <section class="explore-area ">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="intro d-flex justify-content-between align-items-end m-0">
                                <div class="intro-content">
                                    <span>Exclusive Assets</span>
                                    <h3 class="mt-3 mb-0">NFT Explore</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row items">
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_1.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Walking On Air</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Richard</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.5 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_2.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Domain Names</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">John Deo</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>2.7 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_3.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Trading Cards</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Arham</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>2.3 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_4.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Industrial Revolution</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Yasmin</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.8 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_5.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Utility</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Junaid</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.7 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_6.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Sports</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">ArtNox</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.7 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_7.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Cartoon Heroes</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Junaid</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>3.2 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details.html">
                                        <img class="card-img-top" src="assets/img/content/auction_8.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <a href="item-details.html">
                                            <h5 class="mb-0">Gaming Chair</h5>
                                        </a>
                                        <div class="seller d-flex align-items-center my-3">
                                            <span>Owned By</span>
                                            <a href="author.html">
                                                <h6 class="ml-2 mb-0">Johnson</h6>
                                            </a>
                                        </div>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>0.69 ETH</span>
                                            <span>1 of 1</span>
                                        </div>
                                        <a class="btn btn-bordered-white btn-smaller mt-3" href="login.html"><i
                                                class="icon-handbag mr-2"></i>Place a Bid</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footers></footers>

            <div id="scroll-to-top" class="scroll-to-top">
                <a href="#header" class="smooth-anchor">
                    <i class="fas fa-arrow-up"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
    import heads from "./../components/headers"
    import footers from "./../components/footers"

    export default {
        name: "exploreNFT",
        components: {
            heads,
            footers
        }
    }
</script>

<style scoped>

</style>
