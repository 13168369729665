<template>
    <div>
        <header id="header">
            <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
                <div class="container header">
                    <a class="navbar-brand" href="/">
                        <img class="navbar-brand-sticky" src="assets/img/logo/logo.png" alt="sticky brand-logo">
                    </a>
                    <div class="ml-auto"></div>
                    <ul class="navbar-nav items mx-auto">
                        <li v-for="item in list" :key="item" class="nav-item dropdown">
                            <a style="cursor: pointer" @click="goto(item)" class="nav-link">{{item}}</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav toggle">
                        <li class="nav-item">
                            <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                                <i class="fas fa-bars toggle-icon m-0"></i>
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav action">
                        <li class="nav-item ml-3">
                            <a @click="login" class="btn ml-lg-auto btn-bordered-white"><i
                                    class="icon-wallet mr-md-2" style="margin-right: 4px"></i>
                                <span style="font-size: 1rem;" v-if="$store.state.addr==''">Wallet Connect</span>
                                <span  style="color: #fff;font-size: 1rem;" v-if="$store.state.addr!=''">{{subname($store.state.addr)}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <div id="menu" class="modal fade p-0">
            <div class="modal-dialog dialog-animated">
                <div class="modal-content h-100">
                    <div class="modal-header" data-dismiss="modal">
                        Menu <i class="far fa-times-circle icon-close"></i>
                    </div>
                    <div class="menu modal-body">
                        <div class="row w-100">
                            <div class="items p-0 col-12 text-center">
                                <ul class="navbar-nav items mx-auto">
                                    <li v-for="item in list" :key="item" class="nav-item dropdown">
                                        <a style="cursor: pointer" @click="goto(item)" class="nav-link">{{item}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "headers",
        data() {
            return {
                list: [
                    'Home',
                    // 'Create NFT',
                    // 'Explore NFT',
                    'Create ERC20',
                    'Explore ERC20',
                    // 'Activity',
                    'Contact'
                ],
                hensname:''
            }
        },
        methods: {
            subname(txt) {
                if (this.hensname != '') {
                    return this.hensname
                }
                if (txt) {
                    if (txt.length > 41) {
                        return txt.substring(0, 6) + '...' + txt.substring(36, 42)
                    } else {
                        return txt
                    }
                }
            },
            goto(item) {
                if (item == "Home") {
                    if (this.$router.history.current.path != '/') {
                        this.$router.push({path: '/'})
                    }
                }
                if (item == "Create ERC20") {
                    if (this.$router.history.current.path != '/createERC20') {
                        this.$router.push({path: '/createERC20'})
                    }
                }
                if (item == "Explore ERC20") {
                    if (this.$router.history.current.path != '/exploreERC20') {
                        this.$router.push({path: '/exploreERC20'})
                    }
                }
                if (item == "Explore NFT") {
                    if (this.$router.history.current.path != '/exploreNFT') {
                        this.$router.push({path: '/exploreNFT'})
                    }
                }
                if (item == "Create NFT") {
                    if (this.$router.history.current.path != '/createNFT') {
                        this.$router.push({path: '/createNFT'})
                    }
                }
                if (item == "Activity") {
                    if (this.$router.history.current.path != '/activity') {
                        this.$router.push({path: '/activity'})
                    }
                }

                if (item == "Contact") {
                    $('html,body').animate({scrollTop: $('body').height()}, 1000);
                }

            },
            async login() {
                if (typeof (ethereum) !== 'undefined') {
                    this.$store.state.addr = await this.$g.etclogin()
                    if (this.$store.state.addr != '') {
                        let name = await this.$g.getName(this.$store.state.addr);
                        if (name !== null) {
                            this.hensname = name;
                        }
                        ethereum.on('accountsChanged', (res) => {
                            this.login();
                        });
                    }
                }
            },
        },
        mounted() {
            this.login()
            $('#netstorm-preloader').addClass('loaded');
        }
    }
</script>

<style scoped>

</style>
