var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-area",attrs:{"id":"contact"}},[_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 col-sm-6 col-lg-3 res-margin"},[_c('div',{staticClass:"footer-items"},[_c('h4',{staticClass:"footer-title"},[_vm._v("Useful Links")]),_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_c('li',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.submitErc20}},[_vm._v("Submit ERC20")])])])])]),_vm._m(4),_vm._m(5)])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"submitErc20","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(6),_c('div',{staticClass:"modal-body author-area",staticStyle:{"padding-top":"0"}},[_c('form',{staticClass:"item-form card no-hover",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"form-control",attrs:{"type":"text","name":"name","placeholder":"Address","required":"required"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}})])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.submitErc20Fn}},[_vm._v(" Submit ")])])])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-6 col-lg-3 res-margin"},[_c('div',{staticClass:"footer-items"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"。/"}},[_c('img',{staticClass:"navbar-brand-sticky",staticStyle:{"width":"55px"},attrs:{"src":"assets/img/logo/logo.png","alt":""}})]),_c('p',[_vm._v("Courtesy of the HebeBlock Team")]),_c('div',{staticClass:"social-icons d-flex"},[_c('a',{staticClass:"twitter",attrs:{"href":"https://twitter.com/EtcHebeBlock"}},[_c('i',{staticClass:"fab fa-twitter"}),_c('i',{staticClass:"fab fa-twitter"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/createERC20"}},[_vm._v("Create ERC20")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/exploreERC20"}},[_vm._v("Explore ERC20")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-6 col-lg-3 res-margin"},[_c('div',{staticClass:"footer-items"},[_c('h4',{staticClass:"footer-title"},[_vm._v("Contract")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://t.me/hebeOffice"}},[_vm._v("Telegram")])]),_c('li',[_c('a',{attrs:{"href":"https://discord.com/invite/HFBUJ3AKFT"}},[_vm._v("Discord")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-6 col-lg-3"},[_c('div',{staticClass:"footer-items"},[_c('h4',{staticClass:"footer-title"},[_vm._v("Subscribe Us")]),_c('div',{staticClass:"subscribe-form d-flex align-items-center"},[_c('input',{staticClass:"form-control",attrs:{"type":"email","value":"hebeblock@gmail.com","placeholder":"hebeblock@gmail.com"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header",staticStyle:{"padding-top":"0"}},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalCenterTitle"}},[_vm._v("Submit Erc20")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4"},[_c('div',{staticClass:"copyright-left"},[_vm._v("©2023 ETCInscribe, All Rights Reserved.")]),_c('div',{staticClass:"copyright-right"},[_vm._v("Made with "),_c('i',{staticClass:"fas fa-heart"}),_vm._v(" By "),_c('a',{attrs:{"href":"#"}},[_vm._v("ETCInscribe")])])])])])])])
}]

export { render, staticRenderFns }